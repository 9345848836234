import { updateGround, setupGround } from "./ground.js";
import { updateClouds, setupClouds } from "./clouds.js";
import { updateDino, setupDino, getDinoRect, setDinoLose } from "./dino.js";
import { updateCactus, setupCactus, getCactusRects } from "./cactus.js";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit} from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyBDGPCDqlgLjCcBiqcSvhGr8scCzq1ii6U",
  appId: "1:814163332314:web:232165bd7cff8d2b1a69e3",
  authDomain: "chromeosaurus-esx.firebaseapp.com",
  databaseURL: "https://chromeosaurus-esx.firebaseio.com",
  measurementId: "G-SCJQQMLP2M",
  messagingSenderId: "814163332314",
  projectId: "chromeosaurus-esx"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const WORLD_WIDTH = 100;
const WORLD_HEIGHT = 30;
const SPEED_SCALE_INCREASE = 0.0000005;

const worldElem = document.querySelector("[data-world]");
const scoreElem = document.querySelector("[data-score]");
const startScreenElem = document.querySelector("[data-start-screen]");



setPixelToWorldScale();
window.addEventListener("resize", setPixelToWorldScale);
document.addEventListener("submit", function(event){
  event.preventDefault();
  handleStart();
  }, { once: true });
document.addEventListener("visibilitychange" , handleVisibilityChange);

function handleVisibilityChange() {
  if (document.visibilityState === "hidden") {
    location.reload();
  }
}

showHighscore();

let lastTime;
let speedScale;
let score;
let name;

function update(time) {
  if (lastTime === null) {
    lastTime = time;
    window.requestAnimationFrame(update);
    return;
  }
  const delta = time - lastTime;

  updateClouds(delta, speedScale);
  updateGround(delta, speedScale);
  updateDino(delta, speedScale);
  updateCactus(delta, speedScale);
  updateSpeedScale(delta);
  updateScore(delta);
  if (checkLose()) {
    return handleLose();
  }
  lastTime = time;
  window.requestAnimationFrame(update);
}

function checkLose() {
  const dinoRect = getDinoRect();
  return getCactusRects().some((rect) => isCollision(rect, dinoRect));
}


function isCollision(rect1, rect2) {
  return (
    rect1.left < rect2.right &&
    rect1.top < rect2.bottom &&
    rect1.right > rect2.left &&
    rect1.bottom > rect2.top
  );
}

function updateSpeedScale(delta) {
  speedScale += delta * SPEED_SCALE_INCREASE;
}

function updateScore(delta) {
  score += delta * 0.01;
  scoreElem.textContent = Math.floor(score);
}


function handleStart() {
  name = document.getElementById("name").value;
  document.getElementById("name-field").reset();
  lastTime = null;
  speedScale = 1;
  score = 0;
  setupClouds();
  setupGround();
  setupDino();
  setupCactus();
  startScreenElem.classList.add("hide");
  window.requestAnimationFrame(update);
}

function handleLose() {
  setDinoLose();
  storehighScore(name,Math.floor(score));
  showHighscore();
  setTimeout(() => {
    document.addEventListener("submit", function(event){
      event.preventDefault();
      handleStart();
      }, { once: true });
    startScreenElem.classList.remove("hide");
  }, 100);
}

async function storehighScore(name,score) {
  const myCollection = collection(db, "highScores");
  const myDocumentData = {
    name,
    score
    };
  const docRef = await addDoc(myCollection, myDocumentData);
  console.log(docRef.id);
}

function setPixelToWorldScale() {
  let worldToPixelScale;
  if (window.innerWidth / window.innerHeight < WORLD_WIDTH / WORLD_HEIGHT) {
    worldToPixelScale = window.innerWidth / WORLD_WIDTH;
  } else {
    worldToPixelScale = window.innerHeight / WORLD_HEIGHT;
  }

  worldElem.style.width = `${WORLD_WIDTH * worldToPixelScale}px`;
  worldElem.style.height = `${WORLD_HEIGHT * worldToPixelScale}px`;
}

async function showHighscore() {
  let highScores = collection(db,"highScores");
  const snapshot = await getDocs(query(highScores,orderBy("score","desc"),limit(3)));
  if (snapshot.empty) {
    console.log("No matching documents.");
    return;
  }
  let highScoretable = [];
  highScoretable += "<table><tr><td>NAME</td><td>|</td><td>HIGH SCORE</td></tr>";
  snapshot.forEach((doc) => {
    highScoretable += "<tr><td>" + doc.get("name") + "</td><td></td><td>" + doc.get("score") + "</td></tr>";
      });
  highScoretable += "</table>";
  document.querySelector(".highscore-holder").innerHTML = highScoretable;
  setTimeout(() => {
    showHighscore();
  }, 1000);
}


